<template>
  <div class="reset-password-dialog">
    <v-dialog v-model="resetPasswordDialog" width="400">
      <v-card>
        <div class="dialog-header p-16 text-center">
          <span class="dialog-header_title lighten-3">Password Reset</span>
        </div>
        <div class="dialog-body p-16-20">
          <p>Enter the email address you used to create your account, and we will send you the link to reset your password.</p>
          <v-text-field
            solo type="email"
            v-model="$v.resetPasswordData.email.$model"
            :error-messages="resetEmailErrors"
            @change="$v.resetPasswordData.email.$touch()"
            @blur="$v.resetPasswordData.email.$touch()"
            autocomplete="new-email"
          ></v-text-field>
          <div v-if="resetPasswordErrors.length">
            <div class="v-messages theme--light error--text m_b-16">
              <div class="v-messages__message" v-for="(error, index) in resetPasswordErrors" :key="index">{{error.detail}}</div>
            </div>
          </div>
          <div class="text--right">
            <v-btn color="primary" class="mobile-full-width m_b-16" depressed outlined large @click="backToLogin">
              Cancel
            </v-btn>
            <v-btn color="primary" class="mobile-full-width m_b-16" depressed large :loading="loadingResetPassword" @click="resetPassword">
              Reset password
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetPasswordEmailSent" width="400">
      <v-card>
        <div class="dialog-header p-16 text-center">
          <span class="dialog-header_title lighten-3">Reset password</span>
        </div>
        <div class="dialog-body p-16-20">
          <p>All done. Please check your e-mail and follow the instructions.</p>
          <div class="text--right">
            <v-btn color="primary" class="mobile-full-width m_b-16" depressed large @click="hideDialog">
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email , sameAs} from 'vuelidate/lib/validators'
export default {
  props: {
    showResetPassword: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin],
  validations: {
    resetPasswordData: {
      email: { required, email }
    }
  },
  data() {
    return {
      resetPasswordData: {
        email: '',
      },
      loadingResetPassword: false,
      resetPasswordDialog: false,
      resetPasswordEmailSent: false,
      resetPasswordErrors: []
    }
  },
  methods: {
    resetPassword() {
      this.$v.resetPasswordData.$touch()
      if (!this.$v.resetPasswordData.$invalid) {
        this.loadingResetPassword = true
        this.$axios.post('/api/user/v1/password-reset/send-code', {
          'email': this.resetPasswordData.email
        }).then(res => {
          this.loadingResetPassword = false
          this.resetPasswordDialog = false
          this.resetPasswordEmailSent = true
          this.resetPasswordErrors = []
        }).catch(error => {
          this.loadingResetPassword = false
          if (error.response.data.errors) {
            this.resetPasswordErrors = error.response.data.errors
          }
        })
      }
    },
    backToLogin() {
      this.$emit('showLogin')
      this.resetPasswordDialog = false
    },
    hideDialog() {
      this.resetPasswordEmailSent = false
    }
  },
  computed: {
    resetEmailErrors() {
      const errors = []
      if (!this.$v.resetPasswordData.email.$dirty) return errors
      !this.$v.resetPasswordData.email.email && errors.push('Must be valid e-mail')
      !this.$v.resetPasswordData.email.required && errors.push('E-mail is required')
      return errors
    }
  },
  watch: {
    showResetPassword(show) {
      this.resetPasswordDialog = show
    },
    resetPasswordDialog(isSeen) {
      if (!isSeen) {
        this.$emit('hideResetPasswordDialog')
      }
    }
  }
}
</script>
