<template>
    <div class="skeleton-filter">
      <div class="skeleton-filter-head">
        <div class="skeleton-filter-head_name"></div>
        <div class="skeleton-filter-head_reset"></div>
      </div>
      <div class="skeleton-filter-body">
        <div class="skeleton-amenity-wrapper" v-for="as in amenitiesSkeleton" :key="as">
          <div class="skeleton-amenity w-175px"></div>
          <div class="skeleton-amenity w-80px"></div>
          <div class="skeleton-amenity w-143px"></div>
          <div class="skeleton-amenity w-200px"></div>
          <div class="skeleton-amenity w-80px"></div>
          <div class="skeleton-amenity w-50px"></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      amenitiesSkeleton: 2
    }
  }
}
</script>

<style src="./search-product-skeleton.scss" lang="scss" scoped></style>
